.TimeLineContainer {
    margin-top: 8%;
    margin-bottom: 8%;
}

/* Cartas left */
.timeLineBlockLeft {

    display: flex;
    gap: 5%;
    justify-content: flex-start;

}

.TimeLineImgContainer {
    width: 44%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.TimelineTitleimageLeft {
    width: 100%;
    background-color: #262626;
    color: #ffffff;
    font-size: 12px;
    font-family: 'OpenSansBold';
    display: flex;
    justify-content: flex-end;
    padding-right: 2%;
    padding-top: 2%;
    padding-bottom: 2%;
    z-index: 1;
    margin: 0;
    text-transform: uppercase;
}

.TimelineTitleimageRight {
    width: 100%;
    background-color: #262626;
    color: #ffffff;
    font-size: 12px;
    font-family: 'OpenSansBold';
    display: flex;
    justify-content: flex-start;
    padding-left: 2%;
    padding-top: 2%;
    padding-bottom: 2%;
    z-index: 1;
    margin: 0;
    text-transform: uppercase;
}

.timeLineImage {
    width: 100%;
    cursor: pointer;
}

.TimeLineTextContainer {
    display: flex;
    flex-direction: column;
    width: 40%;
}

.timeLineDivider {

    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 6%;
}


.TimeLineDot {
    width: 25px;
    height: 25px;
    padding: 20%;
    border-radius: 50%;
    border: 1px solid #cfcfcf;
    z-index: 1;
}

.TimeLineLine {
    position: absolute;
    width: 1px;
    height: 300px;
    background-color: #e0e0e0;
    margin-top: 32px;
}

.TimeLineTextContainer {
    margin-top: 6%;
}

.TimeLineYear {
    font-family: 'OpenSansSemibold';
    color: #222222;
    margin-bottom: 0;
    font-size: 20px;
}

.TimeLineText {
    font-family: 'OpenSansRegular';
    color: #707070;
    margin-bottom: 0;
    font-size: 14px;
}

/* Cartas right */

.timeLineBlockRight {

    display: flex;
    gap: 5%;
    justify-content: flex-start;
    flex-direction: row-reverse;

}

.TimeLineTextContainerRight {
    display: flex;
    flex-direction: column;
    margin-top: 6%;
    align-items: flex-end;
    text-align: end;
    width: 44%;
}


@media screen and (max-width: 1000px) {

    .timeLineDivider {
        display: none;
    }

    .TimeLineTextContainer {
        margin-top: 0;
    }

    .TimeLineText {
        font-family: 'OpenSansRegular';
        color: #707070;
        margin-bottom: 0;
        font-size: 12px;
    }

    .TimelineTitleimageRight {
        width: 100%;
        background-color: #262626;
        color: #ffffff;
        font-size: 9px;
        font-family: 'OpenSansBold';
        display: flex;
        justify-content: flex-start;
        padding-left: 2%;
        padding-top: 2%;
        padding-bottom: 2%;
        z-index: 1;
        margin: 0;
        text-transform: uppercase;
    }

    .TimelineTitleimageLeft {
        width: 100%;
        background-color: #262626;
        color: #ffffff;
        font-size: 9px;
        font-family: 'OpenSansBold';
        display: flex;
        justify-content: flex-end;
        padding-right: 2%;
        padding-top: 2%;
        padding-bottom: 2%;
        z-index: 1;
        margin: 0;
        text-transform: uppercase;
    }

    .timeLineBlockLeft {
        display: flex;
        gap: 5%;
        justify-content: flex-start;
        margin-top: 10%;
        margin-bottom: 10%;
    }

    .timeLineBlockRight {
        display: flex;
        gap: 5%;
        flex-direction: row-reverse;
        justify-content: center;
        /* align-items: center; */
    }

    .TimeLineTextContainerRight {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        text-align: end;
        width: 44%;
        margin-top: 0%;
    }
}