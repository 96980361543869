.PublicacionSingleTitulosContainer {
  display: flex;
  justify-content: space-between;
}

.PublicacionSingleSubTitulo {
  font-size: 14px;
  font-family: "PoppinsSemibold";
}

.PublicacionSingleLink {
  font-size: 14px;
  font-family: "OpenSansItalic";
  color: #56bcfb;
  text-decoration: none;
}

.PublicacionSingleCompartir {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.PublicacionSingleCompatirContainer {
  display: flex;
  gap: 15px;
  align-items: self-start;
}

.DescargarPublicacionContainer {
  width: 300px;
  height: fit-content;
  background-color: #868686;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.DescargarPublicacionDivider {
  height: 2px;
  background-color: #50bcfb;
  width: 50px;
  margin-top: -13px;
  margin-bottom: 25px;
  align-self: flex-start;
  margin-left: 10%;
}

.DescargarPublicacionContainer p {
  margin-top: 5%;
  color: #ffffff;
  font-size: 16px;
  font-family: "PoppinsSemibold";
  align-self: flex-start;
  margin-left: 10%;
}

.DescargarPublicacion {
  margin-top: -2px;
}

.DescargarPublicacionImagen {
  width: 80%;
}

.DescargarPublicacionArchivo {
  font-size: 12px !important;
  margin-bottom: 15% !important;
  cursor: pointer;
}

.PublicacionSingleLinkPdf {
  font-size: 14px;
  color: #56bcfb;
  font-family: "PoppinsSemibold";
  text-decoration: none;
}

.containerLinkspdf {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 10px;
}

.linkPdf {
  text-decoration: none;
}
