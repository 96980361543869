.BlogNoticiasCard {
  width: 90%;
  border: 0;
  margin-bottom: 2%;
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 3%;
}

.BlogNoticiasCardImg {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.BlogNoticiasCardTextConteiner {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.BlogNoticiasFechaContainer {
  width: 70%;
  height: 5%;
  display: flex;
  justify-content: center;
  align-items: baseline;
  margin-bottom: 10%;
}

.blueLineNoticia {
  background-color: #50bcfb;
  height: 1px;
  width: 71px;
  position: absolute;
  margin-top: 40px;
  margin-right: 250px;
}

.BlogNoticiasDia {
  font-family: "OpenSansBold";
  font-size: 40px;
  margin-right: 7%;
  margin-left: 10%;
  color: #d7d7d7;
}

.BlogNoticiasMes {
  font-family: "OpenSansBold";
  color: #d7d7d7;
  text-transform: uppercase;
}

.BlogNoticiasTitulo {
  font-family: "OpenSansBold";
  font-size: 20px;
  margin-top: 4%;
  margin-bottom: 5%;
}

.BlogNoticiasDivider {
  width: 100%;
  height: 2px;
  background-color: #ececec;
}

.BlogNoticiasTipo {
  font-family: "OpenSansBold";
  color: #838383;
  font-size: 14px;
  margin-top: 2%;
  text-transform: uppercase;
}

.BlogNoticiasTexto {
  color: #838383 !important;
  font-family: "OpenSansRegular" !important;
  font-size: 14px !important;
}

.BlogNoticiasMasInfo {
  font-family: "OpenSansItalic";
  font-size: 14px;
  color: #67c2fc;
  cursor: pointer;
  width: auto;
}

.BlogNoticiaiconArrow {
  margin-left: 3%;
}

.BlogNoticiasLink {
  text-decoration: none;
}

@media screen and (max-width: 1000px) {
  .BlogNoticiasTitulo {
    font-family: "OpenSansBold";
    font-size: 15px;
    margin-top: 4%;
    margin-bottom: 5%;
  }

  .BlogNoticiasMes {
    font-family: "OpenSansBold";
    color: #d7d7d7;
    font-size: 12px;
    text-transform: uppercase;
  }

  .BlogNoticiasDia {
    font-family: "OpenSansBold";
    font-size: 30px;
    margin-right: 7%;
    margin-left: 10%;
    color: #d7d7d7;
  }

  .BlogNoticiasFechaContainer {
    width: 100%;
    height: 17%;
    display: flex;
    justify-content: center;
    align-items: baseline;
    margin-bottom: 10%;
  }

  .BlogNoticiasCardImg {
    width: 100%;
    margin-top: 15%;
  }
}
