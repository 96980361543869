.textoEstatuto {
    font-size: 16px;
    color: #adadad;
    font-family: 'OpenSansRegular';
    margin-top: 3%;
    margin-bottom: 3%;
}

.compartirEstatuto {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 10%;
    gap: 20px;
}

.compartirEstatuto>span {

    font-size: 16px;
    color: #4e4e4e;
    font-family: 'OpenSansBold';
}