#notFound {
    height: 130vh;
}

.notFoundtitulo {

    color: #67c2fc;
    font-size: 14px;
    font-family: 'OpenSansBold';
}

.divider404 {
    height: 1px;
    background-color: #f5f5f5;
    width: 50vw;
    margin-top: 1%;
    margin-bottom: 1%;
}

.notFoundLink {
    color: #777777;
    text-decoration: none;
    font-size: 50px;
}