.AcademicoLink {
    width: 180px;
}

.CartaRelacionado {
    width: 180px;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.CartaRelacionadoImg {
    width: 100%;
}

.CartaRelacionadoTitulo {

    font-family: 'OpenSansMedium';
    font-size: 16px;
    color: #666666;
    margin-top: 15px;
    margin-bottom: 15px;
}

.CartaRelacionadoTexto {
    color: #7a7a7a;
    font-size: 13px;
    font-family: 'OpenSansRegular';
    margin-top: -15px;
}

.alice-carousel__dots {
    display: none;
}

@media screen and (max-width: 1000px) {

    .CartaRelacionadoImg {
        width: 90%;
    }
}