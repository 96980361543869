.BuscadorCard {
    width: 65%;
    border: 0;
    margin-bottom: 2%;
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 3%;
}

.BuscadorCardImg {
    width: 100%;
}

.BuscadorCardTextConteiner {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.BuscadorTitulo {
    font-family: 'OpenSansBold';
    font-size: 20px;
    margin-top: 4%;
    margin-bottom: 5%;
}

.BuscadorDivider {
    width: 100%;
    height: 2px;
    background-color: #ececec;
}

.BuscadorTipo {
    font-family: 'OpenSansBold';
    color: #838383;
    font-size: 14px;
    margin-top: 2%;
}

.BuscadorTexto {
    color: #838383;
    font-family: 'OpenSansRegular';
    font-size: 14px;
}

.BuscadorMasInfo {
    font-family: 'OpenSansItalic';
    font-size: 14px;
    color: #67c2fc;
    cursor: pointer;
    width: auto;
}

.BlogNoticiaiconArrow {
    margin-left: 3%;
}

.BuscadorLink {
    text-decoration: none;
}