.DirectiveCard {
    width: 80%;
    border: 0;
    cursor: pointer;
}

.DirectiveCardImg {
    width: 100%;
    height: 100%;
    margin-bottom: 3%;
}

.DirectiveLink {
    margin-bottom: 3%;
}

.DirectiveTextConteiner {
    background-color: #0f0f0f;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 4%;
    padding-bottom: 4%;
    justify-content: center;
    margin-bottom: 20%;
}


.DirectiveCardTitle {
    font-family: 'OpenSansSemibold';
    color: white;
    font-size: 16px;
}

.iconArrow {
    width: 7%;
    margin-left: 10px;
}

.DirectiveCardJob {

    color: white;
    font-size: 12px;
}