.DivCategory {
    padding: 0 !important;
}

.NoticiasContainer {
    display: grid;
    grid-template-columns: 33.33% 33.33% 33.33%;
    justify-items: center;
    margin-top: 4%;
}

.selectorCategory:hover {
    color: #43b7fb;
}

.PaginationConteiner {

    display: flex;
    justify-content: center;
    margin-bottom: 2%;
    align-items: center;
    height: 100%;
    width: 100%;
}

@media screen and (max-width: 1000px) {
    .NoticiasContainer {
        display: grid;
        grid-template-columns: 100%;
        justify-items: center;
        margin-top: 4%;
    }
}