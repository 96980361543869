.StaffCard {

    width: 80%;
    border: 0;
    margin-bottom: 15%;
}

.StaffCardImg {

    width: 100%;
    margin-bottom: 10%;
}

.StaffCardTitulo {
    padding-bottom: 10%;
    font-size: 20px;
    font-family: "PoppinsSemibold";
}

.StaffCardsector {
    font-family: "OpenSansMedium";
    font-size: 13px;
    color: #808080;
}

.StaffCardMail {
    font-family: "OpenSansMedium";
    text-decoration: none;
    font-size: 13px;
    color: #43B7FB;
}