.DividerAcademico {
  height: 1px;
  background-color: #ececec;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
}

.AcademicosIncorporadosConteiner {
  margin-top: 10px;
}

.Incorporado {
  margin-top: 30px;
  display: flex;
  margin-bottom: 30px;
}

.imgAcademicos {
  width: 100px;
}

.textConteiner {
  padding-left: 10%;
}

.verMas {
  font-size: 12px;
  font-family: "OpenSansSemibold";
  cursor: pointer;
  color: #43b7fb;
}

.tituloIncorporado {
  font-size: 15px;
  font-family: "OpenSansSemibold";
  margin-bottom: -1px;
}

.tipoAcademicoIncorporado {
  font-size: 13px;
  font-family: "OpenSansSemibold";
}

.linkIncorporado {
  text-decoration: 0;
}
