#tituloInstitucional {
  color: white;
  font-family: "LoraBold";
  text-shadow: 2px 2px black;
}

.portada {
  position: relative;
}

.imagenPortada {
  display: block;
  width: 100%;
  height: 100%;
}

.TituloLista {
  font-family: "PoppinsBold";
  font-size: 18px;
}

.listaObjetivos {
  margin-top: 10%;
  word-wrap: break-word;
}

.listaObjetivos > li {
  font-family: "OpenSansRegular";
  color: #878787;
  font-size: 15px;
  margin-bottom: 2%;
  position: relative;
}

.DirectiveCardConteiner {
  margin-top: 6%;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 33% 33% 33%;
  padding-bottom: 10%;
  justify-items: center;
}

@media screen and (max-width: 1000px) {
  .DirectiveCardConteiner {
    margin-top: 6%;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 100%;
    padding-bottom: 10%;
    justify-items: center;
  }
}

.CuerpoAcademicoConteiner {
  max-width: 100% !important;
  display: flex;
  justify-content: center;
  padding-top: 10% !important;
  padding-bottom: 15% !important;
  background-image: url("../../assets/img/grillaAcademicos.jpg");
  background-size: contain;
  background-repeat-y: no-repeat;
}

.LinkAcademicCointeiner {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-shadow: 2px 2px black;
}

#CuerpoAcademico {
  color: white;
  font-family: "LoraBold";
  font-size: 50px;
}

#CuerpoAcademicoText {
  color: white;
  font-family: "LoraBold";
  font-size: 24px;
  margin-top: 7%;
}

.botonAcademicos {
  margin-top: 10%;
}

#staff {
  width: 100%;
}

.staffContainerTotal {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
}

.StaffConteiner {
  margin-top: 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.preTituloStaff {
  color: #aaaaaa;
}

.StaffCardConteiner {
  width: 80%;
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  padding-bottom: 5%;
  padding-top: 5%;
  justify-items: center;
}

.ContactenosIconsContainers {
  width: 100%;
  display: grid;
  grid-template-columns: 33% 33.33% 33.33%;
  height: 20%;
  align-items: flex-start;
  justify-content: center;
  justify-items: center;
}

@media screen and (max-width: 1000px) {
  .StaffCardConteiner {
    width: 80%;
    display: grid;
    grid-template-columns: 100%;
    padding-bottom: 5%;
    padding-top: 5%;
    justify-items: center;
  }

  .ContactenosIconsContainers {
    width: 100%;
    display: grid;
    grid-template-columns: 100%;
    height: 20%;
    align-items: flex-start;
    justify-content: center;
    justify-items: center;
  }
}

.ContactenosIconContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 10%;
}

.IconContacto {
  width: 30px;
  height: 30px;
  background-color: #43b7fb;
  border: 30px solid #43b7fb;
  margin-bottom: 15%;
  border-radius: 50px;
}

.ContactoIconText {
  font-family: "OpenSansSemiBold";
  font-size: 14px;
  margin-bottom: -1px;
  color: #878787;
}

.MapaConteiner {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 7%;
}

.Mapa {
  width: 70%;
  height: 20%;
}
