.container-button-buscador {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 10%;
    padding-right: 10%;
    margin-top: 3%;
    gap: 15px;
    outline: none;
}

.input-buscador {

    width: 90%;
}

.button-buscador {
    width: 10%;
}

.resultados-buscador {
    display: grid;
    grid-template-columns: auto auto;
    min-height: 100vh;
    height: auto;
    width: 100%;
    justify-items: center;
}