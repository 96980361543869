.ObrasCardLink {
    text-decoration: none;
    color: #222222;
    cursor: pointer;
}

.AcademicoLink {
    text-decoration: none;
}

.Carta {
    width: 170px;
    height: auto;
    border: 0;
    cursor: pointer;
}

.CardImg {
    width: 100%;
}

.Cardwrapper {
    position: relative;
    width: 100%;
    height: 60%;
    margin-bottom: 30px;
}

.Cardwrapper::before {

    transition: all .5s ease-in;
    background-color: rgb(0, 0, 0);
    position: absolute;
    content: "";
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    cursor: pointer;
}

.Cardwrapper:hover::before {
    opacity: .5;
}


.CardDivider {

    height: 2px;
    background-color: #50bcfb;
    width: 50px;
    margin-bottom: 10px;
}

.CartaTitulo {
    color: #222222;
    font-size: 17px;
    font-family: 'Poppins';
}

#nombre {
    margin-bottom: -3px;
}

.CartaProvincia {

    font-size: 14px;
    margin-bottom: 15px;
    color: #222222;
}

.CartaProfesion {
    font-size: 12px;
    width: 100%;
    color: #50bcfb
}

.CardDividerFooter {
    width: 100%;
    border-top: 1px solid #ececec;
    margin-top: 10px;
    margin-bottom: 10px;
}

.CartaAcademico {
    font-family: 'OpenSansSemibold';
    font-size: 14px;
    margin-top: 5px;
    color: #777777;
}