.DirectiveFundacionCardConteiner {
  margin-top: 6%;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  padding-bottom: 10%;
  justify-items: center;
}

.DirectiveCardTitle {
  padding: 5px;
  text-align: center;
}

@media screen and (max-width: 1000px) {
  .DirectiveFundacionCardConteiner {
    margin-top: 6%;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
    padding-bottom: 5%;
    justify-items: center;
  }
}
