@media (min-width: 768px) and (max-width: 1024px) {
    #home article:nth-child(3) img {
        max-height: 100%;
        height: 100%;
    }

    #home article:nth-child(5) .col-md-6 img {
        max-height: 100%;
        height: 100%;
    }
}

@media (max-width: 768px) {
    #home .carousel-inner .carousel-item .carousel-caption h3 {
        font-size: 30px;
        line-height: 34px;
    }

    #home .carousel-inner .carousel-item .carousel-caption .text-decr {
        font-size: 18px;
        line-height: 25px;
    }
    
    #home .carousel-inner .carousel-item img {
        height: 72vh;
    }

    .title-article {
        font-size: 30px;
        line-height: 46px;
    }

    .article-subtitle {
        font-size: 16px;
    }

    #home article:nth-child(5) .article-subtitle, #home article:nth-child(6) .article-subtitle, #home article:nth-child(7) .article-subtitle {
        font-size: 12px;
    }

    .descr-article {
        -webkit-hyphens: none;
        hyphens: none;
    }

    #home .instituciones .carousel {
        height: 580px;
    }

    #home .instituciones .carousel-inner .carousel-item img {
        filter: brightness(1);
        height: 450px;
    }

    .newsletter .form-control[type="email"] {
        text-align: center;
    }
}