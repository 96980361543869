.noticiaLink {
  text-decoration: none;
  color: black;
}

.noticiaLink:hover {
  color: black;
}

.NoticiaCarta {
  width: 80%;
  height: 90%;
  border: 0;
  cursor: pointer;
  background-color: #e3e2dd;
  display: flex;
  flex-direction: column;
  margin-bottom: 20%;
}

.NoticiaImageContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.NoticiaCardImg {
  width: 100%;
}

.NoticiaTipo {
  background-color: #231f21;
  position: absolute;
  padding-left: 3%;
  padding-right: 3%;
}

.NoticiaTipo > h5 {
  color: white;
  font-family: "LoraBold";
  font-size: 20px;
}

.NoticiaTextConteiner {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.NoticiaCardTitulo {
  margin-top: 5%;
  font-family: "OpenSansSemibold";
  color: #231f21;
  font-size: 16px;
  margin-bottom: 1%;
  text-align: center;
}

.NoticiaCardSubTitulo {
  font-family: "OpenSansRegular";
  color: #231f21;
  font-size: 14px;
  text-align: center;
}

.NoticiaCardDivider {
  font-family: "OpenSansRegular";
  width: 69%;
  height: 2px;
  background-color: #fefefe;
}

.NoticiaCardTexto {
  font-family: "OpenSansRegular";
  width: 80%;
  margin-left: 0;
  margin-top: 5%;
  text-align: center;
  font-size: 14px;
}

.NoticiaCardMas {
  font-size: 14px;
  color: #43b7fb;
  font-family: "OpenSansRegular";
  font-weight: bold;
}
