#tituloAcademicos {
  color: white;
  font-family: "LoraBold";
  text-shadow: 2px 2px black;
}

.portada {
  position: relative;
}

.imagenPortada {
  display: block;
  width: 100%;
}

.pathTitle {
  font-family: "Lora";
  margin-top: 3%;
  margin-bottom: 0;
}

.pathTitle > span {
  color: #43b7fb;
}

.CardsConteiner {
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-row-gap: 40px;
}

.TituloMenuLateral {
  font-family: PoppinsSemibold;
  font-size: 17px;
  margin-left: 4%;
}

.searchConteiner {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 15%;
  padding-bottom: 20%;
}

.iconSearch {
  margin-top: 3%;
  margin-right: 5%;
  position: absolute;
  color: #a2a2a2;
}

.ListaAcademicos {
  margin-top: 23px;
  padding-left: 10px;
  padding-bottom: 20%;
}

.tipoAcademico {
  display: flex;
  justify-content: space-between;
}

.menuLateral {
  margin-top: 5%;
}

.TituloAcademicos {
  color: #7a7a7a;
  font-family: "OpenSansSemibold";
  font-size: 14px;
  cursor: pointer;
}

.numero {
  color: #7a7a7a;
  font-family: "OpenSansSemibold";
  font-size: 14px;
  cursor: pointer;
}

.tipoAcademico:hover > p {
  color: #222222;
}

.DividerAcademico {
  height: 1px;
  background-color: #ececec;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
}

@media screen and (max-width: 1000px) {
  .searchConteiner {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 10%;
    padding-bottom: 5%;
  }

  .ListaAcademicos {
    margin-top: 23px;
    padding-left: 10px;
    padding-bottom: 4%;
  }

  .DividerAcademico {
    height: 1px;
    background-color: #ececec;
    width: 100%;
    margin-top: 2px;
    margin-bottom: 2px;
  }

  .CardsConteiner {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-row-gap: 40px;
  }
}
