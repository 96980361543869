.AcademicCover {
    width: 100%;
    background-color: #262626;
    height: 70%;
    position: relative;
}

.AcademicCoverInfoContainer {
    margin-top: 10%;
    margin-bottom: 4%;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 55px;
}

.InfoAcademicConteiner {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.AcademicCoverImage {
    width: 30%;
}

.AcademicCoverTitleContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.AcademicCoverTitle {
    font-family: 'PoppinsBold';
    color: white;
    font-size: 32px;
}

.AcademicCoverTipe {
    font-family: 'OpenSansSemibold';
    color: #c5c5c5;
    margin-top: 15%;
    margin-bottom: 2px;
}

.AcademicCoverText {
    font-family: 'OpenSansRegular';
    color: #a8a8a8;
    margin-bottom: 2px;
}

.AcademicCoverVolver {
    color: #40a9e7;
    text-decoration: none;

}

.contenedorTipoDisciplina {
    width: 100%;
    background-color: #40a9e7;
    height: 140px;
    margin-bottom: -75px;
    display: flex;
    justify-content: space-around;
}

.contenedorTipoDisciplinaSecundaria{
    width: 100%;
    background-color: #40a9e7;
    height: 140px;
    margin-bottom: -75px;
    display: flex;
    justify-content: start;
    padding-left: 14%;
}

.ContenedorDatosAzul {
    height: 100%;
    margin-top: 3%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ContenedorDatosAzul h5 {
    color: #222222;
    font-family: 'LoraBold';
    font-size: 20px;
}

.ContenedorDatosAzul p {
    font-family: 'LoraItalic';
    font-size: 16px;
    color: white;
}

.AcademicoDescripcionTotalContainer {
    margin-top: 8%;
}

.RelacionadosTitle {

    font-family: 'OpenSansSemibold';
    font-size: 16px;
    color: #222222;

}

.containerCardRelacionado {
    display: flex;
    width: 100%;
    margin-top: 3%;
    margin-bottom: 3%;
    justify-content: space-between;
    align-items: flex-start;
}


@media screen and (max-width: 1000px) {

    .contenedorTipoDisciplina {
        width: 100%;
        background-color: #40a9e7;
        height: 85px;
        margin-bottom: -75px;
        display: flex;
        justify-content: space-around;
    }

    .contenedorTipoDisciplinaSecundaria{
        width: 100%;
        background-color: #40a9e7;
        height: 85px;
        margin-bottom: -75px;
        display: flex;
        justify-content: center;
        padding-left: 0;
    }

    .ContenedorDatosAzul h5 {
        color: #222222;
        font-family: 'LoraBold';
        font-size: 15px;
    }

    .ContenedorDatosAzul p {
        font-family: 'LoraItalic';
        font-size: 10px;
        color: white;
    }

    .CartaRelacionadoImg {
        width: 90%;
    }
}