.ProxEventContainer {
    width: 100%;
    background-color: #868686;
    display: flex;
}

.ProxEventInterPadding {
    width: 100%;
    padding-left: 15%;
    padding-right: 15%;
    padding-top: 15%;
    padding-bottom: 15%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.ProxEventoTitle {
    width: 100%;
    font-family: 'PoppinsSemibold';
    color: #eaeaea;
    font-size: 18px;
    margin-left: 15%;

}

.ProxEventImg {
    width: 100%;
    margin-top: 7%;
}

.ProxEventFecha {
    color: #43b7fb;
    margin-top: 5%;
    font-family: 'OpenSansBold';
    margin-bottom: 5%;
    font-size: 17px;
}

.proxEventTexto {
    color: #f1f1f1;
    font-family: 'OpenSansBold';
    font-size: 14px;
}

.ProxEventLink {
    text-decoration: none;
}