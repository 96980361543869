.ObrasCard {
  width: 70%;
  border: 0;
  margin-bottom: 15%;
}

.ObrasCardImg {
  width: 100%;
  margin-bottom: 5%;
}

.ObrasCardTitulo {
  padding-bottom: 3%;
  font-size: 20px;
  font-family: "LoraBold";
  text-align: center;
}

.ObrasCardTextConteiner {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ObrasCardtype {
  font-family: "LoraItalic";
  font-size: 14px;
  color: #808080;
}

@media screen and (max-width: 1000px) {
  .ObrasCardTitulo {
    padding-bottom: 3%;
    font-size: 16px;
    font-family: "LoraBold";
    text-align: center;
  }
}
