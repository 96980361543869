.ContainerFondoDocumentalSingle {
  margin-top: 3%;
  display: grid;
  grid-template-columns: 75% 25%;
  margin-bottom: 3%;
}

.BodyFondoDocumental {
  width: 90%;
  display: flex;
  flex-direction: column;
}

.FondoDocumentalSubTitulo {
  font-size: 14px;
  font-family: "OpenSansBold";
  color: #56bcfb;
  margin-top: 2%;
  margin-bottom: 2%;
}

.FondoDocumentalDivider {
  height: 2px;
  width: 100%;
  background-color: #efefef;
  margin-bottom: 2%;
}

.FondoDocumentalTitulo {
  font-size: 20px;
  font-family: "PoppinsSemibold";
}

.FondoDocumentalepigrafe {
  color: #adadad;
  font-family: "OpenSansRegular";
  font-size: 15px;
  margin-top: 2%;
  width: 100%;
}

.FondoDocumentalDescripcion {
  color: #adadad !important;
  font-family: "OpenSansRegular" !important;
  font-size: 15px !important;
  margin-top: 2% !important;
  width: 100% !important;
  margin-bottom: 5% !important;
}

.FondoDocumentalDescripcion p {
  color: #777777 !important;
  font-family: "OpenSansRegular" !important;
  font-size: 15px !important;
  margin-top: 2% !important;
  width: 100% !important;
  margin-bottom: 5% !important;
}
.FondoDocumentalDescripcion p strong {
  color: #777777 !important;
  font-family: "OpenSansRegular" !important;
  font-size: 15px !important;
  margin-top: 2% !important;
  width: 100% !important;
  margin-bottom: 5% !important;
}

.FondoDocumentalDescripcion span strong {
  color: #777777;
  font-family: "OpenSansRegular";
  font-size: 15px;
  margin-top: 2%;
  width: 100%;
  margin-bottom: 5%;
}

.FondoDocumentalDescripcion span strong i {
  color: #777777;
  font-family: "OpenSansRegular";
  font-size: 15px;
  margin-top: 2%;
  width: 100%;
  margin-bottom: 5%;
}

.FondoDocumentalDescripcion span {
  color: #777777;
  font-family: "OpenSansRegular";
  font-size: 15px;
  margin-top: 2%;
  width: 100%;
  margin-bottom: 5%;
}

.FondoDocumentalCompartir {
  display: flex;
  justify-content: flex-end;
  gap: 15px;
  align-items: flex-start;
}

.Compartir {
  font-family: "PoppinsSemibold";
  font-size: 14px;
  color: #313131;
  margin-top: 3px;
}
#FondoDocumentalIconFacebook {
  color: #b9b9b9;
  border: 2px solid #adadad;
  border-radius: 80px;

  height: 15px;
  padding: 5px;
  padding-left: 8px;
  padding-right: 8px;
}

.FondoDocumentalIcon {
  color: #b9b9b9;
  border: 2px solid #adadad;
  border-radius: 80px;

  height: 15px;
  padding: 5px;
}

.VolverFondoDocumental {
  display: flex;
}

.VolverContainer {
  width: 300px;
  height: 100px;
  background-color: #868686;
  display: flex;
  padding-top: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.VolverContainer p {
  color: #ffffff;
  font-size: 16px;
  text-align: center;
  font-family: "PoppinsSemibold";
}

.VolverDocumentalYColeccion {
  border-bottom: #56bcfb 2px solid;
}

.LinkVolver {
  text-decoration: none;
}

@media screen and (max-width: 1000px) {
  .ContainerFondoDocumentalSingle {
    margin-top: 3%;
    display: grid;
    grid-template-columns: 100%;
    margin-bottom: 3%;
    justify-items: center;
  }

  .fondoDocumentalImg {
    height: 50vh;
  }

  .imagenPortada {
    display: block;
    width: 100%;
    height: 20vh;
  }
}
