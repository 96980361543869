/* Archivo CSS personalizado */

/* Cambiar el fondo y tamaño del área clickeable de las flechas */
.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-color: rgba(
    255,
    255,
    255,
    0.5
  ); /* Cambia el color de fondo de las flechas */
  width: 35px; /* Cambia el ancho del área clickeable */
  border-radius: 80px;
  height: 35px; /* Cambia el alto del área clickeable */
  font-size: 10px;
}

.custom-icon-size.carousel-control-prev-icon::before,
.custom-icon-size.carousel-control-next-icon::before {
  font-size: 10px; /* Cambia el tamaño del ícono de la flecha */
}
