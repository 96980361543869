.BlogContainer {
  display: grid;
  grid-template-columns: 70% 30%;
  margin-top: 5%;
}

.NoticiasCardContainer {

  display: flex;
  flex-direction: column;

}

.NoticiasPaginatorContainer {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-left: 6%;
}

@media screen and (max-width: 1000px) {

  .BlogContainer {
      display: grid;
      grid-template-columns: 100%;
      margin-top: 5%;
  }
}