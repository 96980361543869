.subTituloPortada {
  font-family: "loraBold";
  color: white;
  text-shadow: 2px 2px black;
}

.subTituloPortadaSpan {
  color: #58aae8;
}

.SelectCategoryConteiner {
  display: flex;
  justify-content: center;
}

.selectorCategory {
  font-family: OpenSansRegular;
  cursor: pointer;
  color: #898989;
}

.dividerCategory {
  height: 40%;
  margin-top: 0.5%;
  margin-left: 2%;
  margin-right: 2%;
}

.ObrasConteiner {
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
  width: 100%;
  height: auto;
  justify-items: center;
  margin-top: 4%;
  margin-bottom: 4%;
}

.PaginationConteiner {
  display: flex;
  justify-content: center;
  margin-bottom: 2%;
  align-items: center;
  height: 100%;
  width: 100%;
}

@media screen and (max-width: 1000px) {
  .ObrasConteiner {
    display: grid;
    grid-template-columns: 50% 50%;
    width: 100%;
    height: auto;
    justify-items: center;
    margin-top: 4%;
    margin-bottom: 4%;
  }

  .selectorCategory {
    font-family: OpenSansRegular;
    cursor: pointer;
    color: #898989;
    font-size: 12px;
  }
}
