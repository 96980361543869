/* Estilos para el popup */
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .popup {
    max-width: 80%; /* Ancho máximo del popup */
    max-height: 80%; /* Altura máxima del popup */
    overflow: auto; /* Agregar desplazamiento si el contenido excede el tamaño */
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }
  
  .popup img {
    max-width: 100%; /* Asegura que la imagen se ajuste al ancho del popup */
    height: auto;
  }

  .modalHome img{
    cursor: pointer;
    width: 34vw;
  }

  .modalHome .modal-content{
    width: fit-content;
  }
  
  .modalHome .modal-lg {
    justify-content: center;
  }

  @media screen and (max-width: 767px) {
    .modalHome img{
      cursor: pointer;
      width: 87vw;
    }
  }