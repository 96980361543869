.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}

.popup-content {
    position: relative;
    max-width: 100%;
    max-height: 100%;
    padding: 20px;
    overflow: auto;
    text-align: center;
}

.popup-content img {
    max-width: 100%;
    height: auto;
    margin-bottom: 20px;
}

.popup-content button {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background-color: transparent;
    color: #fff;
    font-size: 1.2rem;
    cursor: pointer;
}