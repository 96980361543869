@font-face {
  font-family: "MyriadRegular";
  src: url("../fonts/MYRIADPRO-REGULAR.OTF");
}

@font-face {
  font-family: "PlainBold";
  src: url("../fonts/Plain-Bold.ttf");
}

@font-face {
  font-family: "Myriad Pro Regular";
  src: url("../fonts/Myriad Pro Regular.ttf");
}

@font-face {
  font-family: "OpenSansRegular";
  src: url("../fonts/OpenSans-Regular.ttf");
}

@font-face {
  font-family: "OpenSansItalic";
  src: url("../fonts/OpenSans-Italic.ttf");
}

@font-face {
  font-family: "OpenSansMedium";
  src: url("../fonts/OpenSans-Medium.ttf");
}

@font-face {
  font-family: "OpenSansSemibold";
  src: url("../fonts/OpenSans-Semibold.ttf");
}

@font-face {
  font-family: "OpenSansBold";
  src: url("../fonts/OpenSans-Bold.ttf");
}

@font-face {
  font-family: "OpenSansExtraBold";
  src: url("../fonts/OpenSans-ExtraBold.ttf");
}

@font-face {
  font-family: "PoppinsBold";
  src: url("../fonts/Poppins-Bold.ttf");
}

@font-face {
  font-family: "PoppinsExtraBold";
  src: url("../fonts/Poppins-ExtraBold.ttf");
}

@font-face {
  font-family: "PoppinsSemibold";
  src: url("../fonts/Poppins-SemiBold.ttf");
}

@font-face {
  font-family: "LoraItalic";
  src: url("../fonts/Lora-Italic.ttf");
}

@font-face {
  font-family: "LoraBold";
  src: url("../fonts/Lora-Bold.ttf");
}

* {
  font-family: "OpenSansRegular";
}

.bg-gris-oscuro {
  background-color: white !important;
}

@media screen and (max-width: 767px) {
  .bg-gris-oscuro {
    background-color: white !important;
  }
}

.w-70 {
  width: 70% !important;
}

.section-head {
  background-color: #404040;
  color: #aaaaaa;
  font-size: 14px;
  line-height: 20px;
}

.section-head a {
  color: #aaaaaa;
  text-decoration: none;
}

.section-head a:hover {
  color: #969696;
}

.section-head-bottom {
  font-size: 14px;
  color: #222222;
}

.container-icon {
  background-color: #43b7fb;
  border-radius: 50%;
  width: 37px;
  height: 37px;
  border: 3px solid #d8edfa;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 14px;
}

.bg-dark-opacity {
  background-color: rgba(0, 0, 0, 0.15);
}

.menu-container {
  z-index: 9999;
}

.menu-container .nav-link {
  padding-bottom: 1.2em;
  padding-left: 0px !important;
  padding-top: 0px;
  padding-right: 0px !important;
  margin: 1.2em 0 0 0;
}

.menu-container a.nav-link {
  color: #fbfbfb;
  font-size: 13px;
  line-height: 19px;
  font-family: "OpenSansSemibold";
}

.menu-container a.nav-link:hover {
  color: #cacaca;
}

.menu-container a.nav-link.active {
  color: #a8d8f4;
  border-bottom: 3px solid #a8d8f4;
}

@media screen and (max-width: 1000px) {
  .menu-container a.nav-link {
    color: grey;
  }
}

footer {
  background-color: #222222;
}

footer h6 {
  color: white;
  font-family: "PoppinsSemibold";
  font-size: 16px;
  line-height: 20px;
}

footer h5 {
  color: #e4e4e4;
  font-family: "OpenSansSemibold";
  font-size: 16px;
  line-height: 20px;
}

footer p {
  color: #ababab;
  font-family: "OpenSansRegular";
  font-size: 13px;
  line-height: 26px;
  letter-spacing: 0.25;
}

.redirect-link {
  font-family: "OpenSansItalic";
  color: #43b7fb;
  font-size: 14px;
  line-height: 30px;
  letter-spacing: 0.25;
  text-decoration: none;
}

.redirect-link:hover {
  color: #28b0ff;
}

footer ul {
  list-style: none;
}

footer ul li a {
  color: #909090;
  font-family: "OpenSansRegular";
  font-size: 16px;
  line-height: 36px;
  letter-spacing: 0.1;
  text-decoration: none;
}

footer ul li a:hover {
  color: #7a7a7a;
}

.iconinfo {
  color: #43b7fb;
  font-size: 14x;
}

footer .legales {
  background-color: #161616;
  color: #909090;
}

footer .container-icon {
  background-color: transparent;
  border-width: 2px !important;
  border-color: #444444;
  color: #444444;
  font-size: 13px;
}

.newsletter p,
#icon-input-mail svg,
.newsletter .form-control[type="email"]::placeholder,
.newsletter .form-control[type="email"] {
  font-family: "OpenSansItalic";
  font-size: 13px;
  line-height: 26px;
  letter-spacing: 0.25;
  color: #7e7e7e;
}

.newsletter .form-control[type="email"] {
  background-color: transparent;
  outline: none;
  box-shadow: none;
}

footer hr {
  opacity: 1;
  color: #343434;
  margin: 0;
}

/* =========================================== HOME =========================================== */
#home .carousel-inner .carousel-item img {
  height: 100vh;
  object-position: bottom;
  object-fit: cover;
}

#plenario {
  height: 100vh;
  object-fit: cover;
}

#home .carousel-inner .carousel-item img:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 1);
}

#home .carousel-inner .carousel-item .carousel-caption {
  padding: 0;
  top: 0;
  bottom: 0;
  margin: auto 0;
  height: fit-content;
}

#home .carousel-inner .carousel-item .carousel-caption h3 {
  font-family: "PoppinsSemibold";
  font-size: 34px;
  line-height: 38px;
  text-shadow: 2px 2px black;
}

#home .carousel-inner .carousel-item .carousel-caption .text-decr {
  font-family: "MyriadRegular";
  font-size: 22px;
  line-height: 29px;
  letter-spacing: 2px;
  text-shadow: 2px 2px black;
}

#home .carousel-inner .carousel-item .footer-caption button {
  font-family: "MyriadRegular";
  font-size: 14px;
  line-height: 28px;
  letter-spacing: 1.5px;
  background-color: rgba(51, 102, 191, 0.8);
  border-color: white;
}

#home .carousel-inner .carousel-item .carousel-caption .text-important {
  font-family: "OpenSansBold";
}

.card-info-agenda h6 {
  font-size: 18px;
  line-height: 24px;
  font-family: "OpenSansBold";
  color: #c2c2c2;
}

.card-info-agenda h6 > span.border-title {
  width: 13.8px;
  height: 3.8px;
  background-color: #a8d8f4;
  display: inline-block;
  margin-right: 11.5px;
}

.card-info-agenda h4 {
  font-size: 22px;
  line-height: 28px;
  font-family: "OpenSansMedium";
  color: white;
}

.card-info-agenda p {
  font-size: 16px;
  line-height: 22px;
  font-family: "OpenSansRegular";
  color: white;
}

.card-info-agenda hr {
  border-color: #969696;
  color: #969696;
  opacity: 1;
}

#home article:nth-child(1) {
  background-color: white;
}

#home article:nth-child(2) {
  background: linear-gradient(to bottom, #ffffff 38.5%, #222222 38.5%);
}

.title-article {
  font-size: 32px;
  line-height: 48px;
  font-family: "PoppinsBold";
  color: #222222;
  text-transform: uppercase;
}

#home article:nth-child(2) .text-descr {
  font-size: 16px;
  line-height: 24px;
  color: #222222;
}

.horizontal-line {
  width: 40px;
  height: 1px;
  background-color: #969696;
  display: inline-block;
}

.descr-article {
  font-size: 15px;
  line-height: 28px;
  font-family: "OpenSansMedium";
  color: #777777;
  hyphens: auto;
}

.poppins-extrabold {
  font-family: "PoppinsExtraBold" !important;
}

#home article:nth-child(3) .col-md-4 {
  background-color: #fafafa;
}

#home article:nth-child(3) img {
  max-height: 554.22px;
  object-fit: cover;
  object-position: bottom;
  transform: scale(1.1);
}

#home article:nth-child(3) .col-xl-8 > .position-absolute {
  background-color: rgba(0, 0, 0, 0.2);
  color: white;
}

#home article:nth-child(3) .col-xl-8 > .position-absolute h6 {
  font-size: 18px;
  line-height: 22px;
  font-family: "PoppinsExtraBold";
}

#home article:nth-child(3) .col-xl-8 > .position-absolute p {
  font-size: 14px;
  line-height: 18px;
  font-family: "OpenSansSemibold";
}

.article-subtitle {
  font-family: "LoraBold";
  text-align: center;
  font-size: 18px;
  letter-spacing: 1.5px;
  color: #43b7fb;
  text-transform: uppercase;
}

#home article:nth-child(4) .card .card-title {
  font-size: 14px;
  line-height: 16px;
  font-family: "OpenSansBold";
  color: #222222;
}

#home article:nth-child(4) .card .card-text {
  font-size: 14px;
  line-height: 22px;
  font-family: "OpenSansMedium";
  color: #888888;
}

#home article:nth-child(4) .row-cols-md-3 {
  --bs-gutter-y: 3rem;
  --bs-gutter-x: 4.5rem;
}

#home article:nth-child(4) .card .icon {
  background-color: #333333;
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#home article:nth-child(4) .card .card-body hr {
  margin: 0;
  opacity: 1;
  border-color: #43b7fb;
  position: absolute;
  width: 45px;
  transform: translate(-75%, 0px);
  z-index: 9999;
}

#home article:nth-child(4) .card .icon > img {
  max-height: 50px;
}

.translate-middle-none {
  transform: translate(0, 0) !important;
}

#home article:nth-child(5),
#home article:nth-child(6) {
  background-color: #fafafa;
}

#home article:nth-child(5) .col-md-6 img {
  object-fit: cover;
  object-position: center;
  max-height: 519px;
}

#home article:nth-child(5) .article-subtitle,
#home article:nth-child(6) .article-subtitle,
#home article:nth-child(7) .article-subtitle {
  font-size: 14px;
}

#home article:nth-child(5) .descr-article {
  hyphens: none;
}

.opensans-italic {
  font-family: "OpenSansItalic" !important;
}

.wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.wrapper div img {
  max-width: 338px;
  max-height: 294px;
  object-fit: cover;
  object-position: center;
}

.opensans-bold {
  font-family: "OpenSansBold" !important;
}

#home article:nth-child(7) img {
  max-height: 300px;
  object-fit: cover;
  object-position: center;
}

.instituciones h3 {
  font-family: "OpenSansExtraBold";
  font-size: 22px;
  text-transform: uppercase;
  color: #444444;
  text-decoration: none !important;
}

.instituciones p {
  font-size: 14px;
  color: #777777;
  text-decoration: none !important;
}

.instituciones img {
  height: 384px;
}

.bg-documental {
  background-color: rgba(0, 0, 0, 0.75);
}

.bg-documental .container-icon {
  border: none;
  background-color: rgba(255, 255, 255, 0.6);
  font-size: 12px;
  color: rgba(0, 0, 0, 0.75);
}

.bg-documental p {
  color: white;
  font-family: "LoraBold";
  font-size: 18px;
}

.bg-documental p:last-child {
  font-size: 14px;
  font-family: "LoraItalic";
}

.item-academico {
  text-decoration: none;
}

.item-academico:hover .icon {
  background-color: #a8d8f4 !important;
}

.linkToPage {
  color: #444444;
}

.FundacionLink {
  color: white;
  text-decoration: none;
  padding: 0;
}

.FundacionLink:hover {
  color: white;
}

.textoAgenda {
  color: white;
}

.containerEtiquetasAcademicos {
  width: 100%;
  display: flex;
  gap: 20px;
}

.imagenIcon {
  font-family: "PlainBold";
  font-size: 40px;
  background-color: #a8d8f4;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
}

.etiquetasAcademicosText {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.etiquetasAcademicosText h5 {
  font-family: "OpenSansBold";
  font-size: 16px;
  color: #222222;
}

.etiquetasAcademicosText p {
  font-family: "OpenSansMedium";
  font-size: 14px;
  color: #b0b0b0;
}

.CorreoLink {
  text-decoration: none;
  color: #222222;
}

.Vinieta {
  color: #28b0ff;
  margin-bottom: 6px;
  font-size: 14px;
  font-weight: 500;
  justify-content: center;
  display: flex;
  font-style: italic;
}

.textInterno {
  color: black;
  border-bottom: 1px solid gray;
}

.mbvinieta {
  margin-bottom: 10px;
}

.textEjemeplo {
  color: #878787;
}

@media screen and (max-width: 400px) {
  .logocontainerheadermobile {
    margin: 0;
    width: 50%;
  }

  .logocontainerheadermobile img {
    width: 100%;
    margin: 0;
  }
}

/* ============================================================================================ */
